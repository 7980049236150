@keyframes scroll {
  100% {
    background-position: 0 1000px;
  }
}

.info {
  pointer-events: none;
  inline-size: 21rem;
  opacity: 0;
  padding: .75rem;
  color: silver;
  padding-block: 1.125rem;
  padding-inline: .75rem;
  font-size: .845em;
  transition: opacity .5s;
}

.multipleHeaders {
  width: 100%;
  overflow: hidden;
}

.multipleHeaders > :first-child {
  flex-grow: 1;
  flex-shrink: 0;
  padding-right: 0;
}

.multipleHeaders > :not(:first-child) {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: .2rem;
  overflow: hidden;
}

.multipleHeaders h1 {
  text-overflow: ellipsis;
  overflow: hidden;
}

.multipleHeaders > a:not(:first-child) {
  line-height: 1rem;
}

.segmentLabel {
  --border-color: #0ff;
  --border-color: transparent;
  text-shadow: 1px 1px 0px var(--border-color), -1px -1px 0px var(--border-color), -1px 1px 0px var(--border-color), 1px -1px 0px var(--border-color);
}

.fg {
  z-index: 9;
}

a.weblink:hover {
  background-image: linear-gradient(135deg, #000 25%, #0000 25%), linear-gradient(225deg, #000 25%, #0000 25%), linear-gradient(45deg, #000 25%, #0000 25%), linear-gradient(315deg, #000 25%, #0000 25%);
}

img {
  transform-origin: center;
  transform: translateZ(-.08px);
}

.info.toc {
  max-height: calc(var(--ownInfoLines) * var(--unit) );
  margin: -.5rem -.75rem -.75rem;
  padding: .5rem .75rem;
  display: block;
  overflow: auto;
}

.info.toc li {
  display: inline-block;
}

.body h3 {
  max-width: 21rem;
  color: #4f8;
  margin: 1.5rem 0;
  padding: 0;
  font-family: subarumedium, sans;
  font-size: 1em;
}

.body p.meta {
  color: #000;
  background: #fff;
  margin: 0;
  padding: 1.5rem 1.25rem;
}

.body p.meta img {
  width: var(--column);
  max-width: calc(100% + 2.5rem);
  margin: -1.5rem -1.25rem 1.25rem;
  display: block;
}

.segment-heading {
  color: #fff;
  letter-spacing: -.5px;
  max-width: 21rem;
  margin: 1.5rem 0;
  padding: 0 1.125rem;
  font-family: space_monoregular;
  font-size: 1.2rem;
  position: relative;
  left: -2px;
}

.body.illustrative p {
  max-width: calc(var(--column) * var(--own-columns) );
}

.richtext > p, .richtext > h2 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.richtext > h2 {
  color: #4f8;
}

/*# sourceMappingURL=index.a81f1033.css.map */
