@keyframes scroll {
    100% {
        background-position: 0px 1000px;
    }
}

.info {
    pointer-events: none;
    inline-size: 21rem;
    opacity: 0;
    padding: 0.75rem;
    padding-inline: 0.75rem;
    padding-block: 1.125rem;
    color: silver;
    transition: opacity 0.5s;
    font-size: 0.845em;
}

.multipleHeaders {
    overflow: hidden;
    width: 100%;


    >*:first-child {
        flex-shrink: 0;
        flex-grow: 1;
        padding-right: 0rem;
    }

    >*:not(:first-child) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 0.2rem;
    }


    h1 {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    >a {
        &:not(:first-child) {
            line-height: 1rem;
        }
    }
}



.segmentLabel {
    --border-color: #0ff;
    --border-color: transparent;
    text-shadow: 1px 1px 0px var(--border-color), -1px -1px 0px var(--border-color), -1px 1px 0px var(--border-color), 1px -1px 0px var(--border-color);
}





.fg {
    z-index: 9;
}


a.weblink {
    &:hover {
        background-image: linear-gradient(135deg, #000 25%, transparent 25%), linear-gradient(225deg, #000 25%, transparent 25%), linear-gradient(45deg, #000 25%, transparent 25%), linear-gradient(315deg, #000 25%, rgba(0, 0, 0, 0) 25%);
    }
}

img {
    transform-origin: center;
    transform: translateZ(-0.08px);
}



.info.toc {
    li {
        display: inline-block;
    }

    margin: -0.75rem;
    margin-top: -0.5rem;
    display: block;
    padding: 0.5rem 0.75rem;
    max-height: calc(var(--ownInfoLines) * var(--unit));
    overflow:auto;

    
}

.body h3 {
    max-width: 21rem;
    font-family: subarumedium, sans;
    padding: 0;
    font-size: 1em;
    color: #4F8;
    margin: 1.5rem 0 1.5rem 0;
}

.body p.meta {
    background:white; color:black;
    margin: 0; padding: 1.5rem 1.25rem;

    img {display:block;
        margin: -1.5rem -1.25rem;
        margin-bottom: -1.5rem;
      width: var(--column);
      max-width: calc(100% + 2.5rem);
      margin-bottom: 1.25rem;
    }
}

.segment-heading {
    font-family: space_monoregular;
    color: white;
    font-size: 1.2rem;
    position: relative;
    left: -2px;
    letter-spacing: -0.5px;
    max-width: 21rem;
    padding: 0 1.125rem;
    margin: 1.5rem 0 1.5rem 0;

}


.body.illustrative {
    p {
        max-width: calc(var(--column) * var(--own-columns));
    }
}

.richtext {

    >p,
    >h2 {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    >h2 {
        color: #4f8;
    }
}

